import React from 'react'
import { PageContext, Project } from '../lib/sanity-types'
import { DetailPage } from '../components/detail-page'

const ProjectPage: React.FC<{
  pageContext: PageContext & { project: Project }
}> = ({ pageContext }) => {
  return (
    <DetailPage
      related={pageContext.project.related}
      seo={pageContext.project.seo}
      pageContext={pageContext}
      name={pageContext.project.name}
      description={pageContext.project.description}
      content={pageContext.project.content}
    />
  )
}

export default ProjectPage
